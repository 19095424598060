import {Popover} from 'element-ui';
import {isHtmlElement} from 'element-ui/src/utils/types';
import {isVNode} from 'element-ui/src/utils/vdom';

export default {
    name: "ReferenceChangeablePopover",
    extends: Popover,
    props: {
        forced: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        reference(newVal, oldVal) {
            const reference = isHtmlElement(newVal) ? newVal : (isVNode(newVal) ? newVal.$el : null);
            if (reference == null) {
                throw new Error("reference must be dom or Vue's vdom");
            }

            this.referenceElm = this.$refs.reference = reference;

            this.destroyPopper();
            if (this.popperJS) {
                if (this.forced) {
                    //完全销毁popperJS实例
                    this.doDestroy0();
                } else {
                    //只修改reference
                    this.popperJS._reference = this.referenceElm;
                }
            }
            this.updatePopper();
        }
    },
    methods: {
        doDestroy0() {
            this.popperJS.destroy();
            this.popperJS = null;
        }
    }
}
