import {downloadFileAsBlob, downloadPrivateFileAsBlob} from '@/service/system/attachment';
import {getTenantKey} from '@/util/auth'


export default {
    name: 'AttachmentPreviewer',
    methods: {
        async getBlobUrlOfAttachment(attachmentId, privately = false) {
            // 文件获取不到时不能直接提示错误 wc
            const downloader = privately ? downloadPrivateFileAsBlob : downloadFileAsBlob;
            let blob;
            await downloader(attachmentId).then(fileAsBlob => {
                blob = new Blob([fileAsBlob]);
            }).catch((error) => {
                console.log("附件加载错误：" + error);
                return "";
            });
            return this.createBlobUrlOfFile(blob);
        },
        createBlobUrlOfFile(blob) {
            if (!blob) return "";
            let objectURL = this.blobUrlWeakMap.get(blob);
            if (!objectURL) {
                objectURL = URL.createObjectURL(blob);
                this.blobUrls.push(objectURL);
                this.blobUrlWeakMap.set(blob, objectURL);
            }
            return objectURL;
        },
        formatToDownloadUrl(path, name) {
            return `${this.$bjggs.DOWNLOAD_URI}${path}?download=1&name=${name}&t=${getTenantKey()}`;
        },
        formatToDisplayUrl(path) {
            //展示，仅用于在web端展示图片类数据
            return `${this.$bjggs.DOWNLOAD_URI}${path}?download=0&t=${getTenantKey()}`;
        }
    },
    beforeCreate() {
        this.blobUrls = [];
        this.blobUrlWeakMap = new WeakMap();
    },
    destroyed() {
        this.blobUrls.forEach(url => URL.revokeObjectURL(url));
    }
}
