import request from '@/util/request'

export function downloadFileAsBlob(fileId) {
    return request({
        url: `upload/download/${fileId}`,
        method: 'post',
        responseType:'blob',
        noErrorMsg: true
    })
}

export function downloadPrivateFileAsBlob(fileId) {
    return request({
        url: `upload/download-private/${fileId}`,
        method: 'post',
        responseType:'blob',
        noErrorMsg: true
    })
}

export function getThumbnailAsBlob(fileId) {
    return request({
        url: `upload/preview/${fileId}.png`,
        method: 'post',
        responseType:'blob',
        noErrorMsg: true
    })
}

export function getPrivateThumbnailAsBlob(fileId) {
    return request({
        url: `upload/private-thumbnail/${fileId}`,
        method: 'post',
        responseType:'blob',
        noErrorMsg: true
    })
}
export function saveBase64(data) {
    return request({
        url: `upload/save/base64`,
        method: 'post',
        data
    })
}
