<template>
    <div>
        <viewer
                :images="images"
                :options="options"
                class="viewer"
                ref="viewer"
                @inited="inited"
                v-if="images && images.length"
        >
            <img
                    v-for="{source, thumbnail, alt} in images"
                    :src="thumbnail"
                    :data-source="source"
                    :alt="alt"
                    :key="source"
                    class="image"
            >
        </viewer>
    </div>
</template>

<script>
    import Vue from "vue";
    import Viewer from "v-viewer";
    import "viewerjs/dist/viewer.css";
    import {downloadFile} from '@/util/urls'


    Vue.use(Viewer);
    export default {
        data() {
            return {
                options: {
                    url: "data-source",
                    zIndex: 3000,
                    toolbar: {
                        zoomIn: 4,
                        zoomOut: 4,
                        oneToOne: 4,
                        reset: 4,
                        prev: 4,
                        play: {
                            show: 4,
                            size: 'large',
                        },
                        next: 4,
                        rotateLeft: 4,
                        rotateRight: 4,
                        flipHorizontal: 4,
                        flipVertical: 4,
                        download: {
                            show: 4,
                            click: () => downloadFile(this.$viewer.image.src, this.$viewer.image.alt)
                        },
                    }
                },
                index: 0,
                images: [],
            };
        },
        methods: {
            inited(viewer) {
                this.$viewer = viewer;
                //因可能与其他组件(element ui dialog/...)冲突，屏蔽Escape键入事件，手动关闭并禁止事件传播
                const escapeKeyShield = function (event) {
                    if (event.code === 'Escape' || event.keyCode === 27) {
                        viewer.hide();
                        event.stopPropagation();
                    }
                };
                const element = viewer.element;
                element.addEventListener('shown', function () {
                    document.addEventListener('keydown', escapeKeyShield, true);
                    document.addEventListener('keyup', escapeKeyShield, true);
                });
                element.addEventListener('hide', function () {
                    document.removeEventListener('keydown', escapeKeyShield, true);
                    document.removeEventListener('keyup', escapeKeyShield, true);
                });
                this.$viewer.view(this.index);
            },
            view(index) {
                this.index = index;
                this.$viewer.view(this.index);
            },
            show(images, index = 0) {
                if (this.images === images) {
                    this.view(index);
                    return;
                }
                this.images = images;
                this.index = index
            }
        }
    };
</script>

<style lang="scss" scoped>
    .image {
        display: none;
    }
</style>
<style>
    .viewer-loading > img {
        display: none; /* hide big images when it is loading */
    }

    .viewer-download {
        color: #fff;
        font-family: FontAwesome, serif;
        font-size: 0.75rem;
        line-height: 1.5rem;
        text-align: center;
    }

    .viewer-download::before {
        content: "\f019";
    }
</style>
