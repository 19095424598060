<template>
    <el-upload
        ref="uploader"
        v-bind="$attrs"
        v-on="$listeners"
        :action="action"
        :style="$vnode.data.style"
        :class="$vnode.data.class"
        :before-upload="innerBeforeUpload"
        :headers="headersWithAuthorization"
        :on-success="uploadPostHandle"
        :on-error="onError"
        :on-preview="internalPreview"
        :on-remove="internalRemove"
        :multiple="multiple"
        :limit="limit"
        :on-exceed="exceed"
        :disabled="disabled"
        :file-list="internalFileList"
        :list-type="listType"
        @click.native="handleClick"
    >
        <div v-if="listType === 'picture-card'" slot="file" slot-scope="{file}">
            <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                :alt="file.name"
                @click="internalPreview(file)"
            >
            <span class="image-file-name" @click="internalPreview(file)">
                {{ file.name }}
            </span>
        </div>
        <slot></slot>
        <template slot="tip">
            <slot name="tip"></slot>
        </template>
        <template slot="trigger">
            <slot name="trigger">
                <el-button size="small" type="primary" v-if="!disabled">选取文件</el-button>
            </slot>
        </template>

        <image-viewer ref="imageViewer"/>
        <reference-changeable-popover :reference="selectedFileElement" v-model="contextmenuVisible">
            <ul class="contextmenu" @click="hideContextmenu">
                <li v-show="isPreviewable(selectedFile)" @click="previewImage">预览</li>
                <li @click="download">下载文件</li>
                <li v-if="!disabled" @click="internalRemove(selectedFile)">删除</li>
            </ul>
        </reference-changeable-popover>
    </el-upload>
</template>

<script>
import ImageViewer from '../../Upload/ImageViewer';
import 'viewerjs/dist/viewer.css'
import {isFunction} from 'min-dash'
import {getTenantKey} from '@/util/auth'
import {getPrivateThumbnailAsBlob} from '@/service/system/attachment'
import ReferenceChangeablePopover from '@/components/x/popover/reference-changeable-popover'
import AttachmentPreviewer from './AttachmentPreviewer'
import {hasClass} from 'element-ui/src/utils/dom';
import {remove} from '@/util/objects'
import {downloadFile} from '@/util/urls'

export default {
    name: "XUploader",
    components: {ImageViewer, ReferenceChangeablePopover},
    mixins: [AttachmentPreviewer],
    model: {prop: 'fileList', event: 'update'},
    props: {
        action: {
            type: String,
            default() {
                const $bjggs = this.$bjggs;
                return `${$bjggs.BASE_API}/${this.private ? $bjggs.UPLOAD_PRIVATE_URI : $bjggs.UPLOAD_URI}`;
            }
        },
        //大小，单位:mb,默认10mb
        size: {
            type: Number,
            default: 10
        },
        limit: {
            type: Number,
            default: 1000
        },
        headers: Object,
        onSuccess: Function,
        onError: Function,
        fileList: {
            type: Array,
            default: () => []
        },
        beforeUpload: Function,
        listType: {
            type: String,
            default: 'text',
            validator: value => ['picture', 'picture-card', 'text'].indexOf(value) !== -1,
        },
        multiple: {
            type: Boolean,
            default: false
        },
        disabled: { //是否可编辑
            type: Boolean,
            default: false
        },
        private: {  //是否是私有型文件
            type: Boolean,
            default: false
        },
        picLimit: { // 是否图片长宽判断
            type: Boolean,
            default: false
        },
        picWidth: {
            type: Number,
            default: 1920
        },
        picHeight: {
            type: Number,
            default: 1080
        },
        fileType:{ // 允许的附件类型
            type: String,
            default: '', // 多个类型用,隔开 jpg,png,xls
        }
    },
    data() {
        return {
            selectedFile: null,
            selectedFileElement: null,
            contextmenuVisible: false
        }
    },
    computed: {
        token() {
            const state = this.$store.state;
            const userState = state.user;
            if (userState && userState.user && userState.user.token) {
                return userState.user.token;
            }
            const customerState = state.customer;
            return customerState && customerState.customer && customerState.customer.token
        },
        headersWithAuthorization() {
            let headers = this.headers || {};
            return {Authorization: 'Bearer ' + this.token, 'X-Tenant-Id': getTenantKey(), ...headers};
        },
        internalFileList: {
            get() {
                //用于element ui的图片预览
                if (this.listType === 'picture' || this.listType === 'picture-card') {
                    return this.fileList.map(file => {
                        if (file.raw) {
                            return file;
                        }
                        if (file.id && !file.url) {
                            if (this.private) {
                                //set url later
                                getPrivateThumbnailAsBlob(file.id)
                                    .then((data) => {
                                        const blob = new Blob([data]);
                                        const blobUrl = this.createBlobUrlOfFile(blob);
                                        this.$set(file, 'url', blobUrl);
                                    });
                            } else {
                                file.url = this.formatToDisplayUrl(file.path);
                            }
                            return file;
                        }
                    });
                }
                return this.fileList;
            },
            set(newVal) {
                this.$emit('update', newVal);

                let simplifiedPaths;
                if (newVal && newVal.length) {
                    simplifiedPaths = newVal.map(({path}) => path).join(',');
                } else {
                    simplifiedPaths = null;
                }
                //更新简化的文件路径串path1,path2，主要在图片展示时使用
                this.$emit('update:simplifiedPaths', simplifiedPaths);
            }
        }
    },
    methods: {
        uploadPostHandle(response, file, uploadFiles) {
            if (this.multiple && this.uploadingFileCount > 0) {
                this.uploadingFileCount--;
            }
            //自定义fail校验
            if (response && response.code !== 200) {
                file.status = 'fail';
                //上传失败
                isFunction(this.onError) && this.onError(response, file, uploadFiles);
            } else {
                isFunction(this.onSuccess) && this.onSuccess(file, response.data);
                const {name, percentage, size, status, uid, url, raw} = file;
                const {path, extension} = response.data;
                const uploadedFile = {
                    name,
                    percentage,
                    size: parseInt(size / 1024),
                    status,
                    uid,
                    url,
                    path,
                    extension,
                    raw
                };
                if (this.multiple) {
                    //多文件上传时的处理
                    if (this.uploadingFileCount === 0) {
                        this.internalFileList = [...this.fileList, ...this.fileQueueNotUpadted, uploadedFile];
                    } else {
                        this.fileQueueNotUpadted.push(uploadedFile);
                    }
                } else {
                    this.internalFileList = [...this.fileList, uploadedFile];
                }
            }
        },
        isImage(filename = "") {
            return /\.(png|jpg|gif|jpeg|webp)$/.test(filename.toLowerCase());
        },
        innerBeforeUpload(rawFile) {
            return new Promise( async (resolve, reject) => {
                //文件大小验证
                if (rawFile.size > (this.size * 1024 * 1024)) {
                    this.$msgError(`文件大小超过限制，最大${this.size}MB`);
                    return reject(false)
                }
                if (rawFile.name.length > 50) {
                    this.$msgError(`文件名称最长50个字符`);
                    return reject(false)
                }
                // 图片判断尺寸
                if (this.picLimit) {
                    let islimit = await this.limitFileWH(rawFile).then(res => {
                        if (res) return true
                    });
                    if (!islimit) return reject(false)
                }
                // 判断图片类型
                if(this.fileType && this.fileType.length > 0){
                    const testmsg = rawFile.name.substring(rawFile.name.lastIndexOf('.')+1);
                    if(this.fileType.indexOf(testmsg) === -1){
                        this.$msgError(`上传类型错误，只允许上传${this.fileType}格式！`);
                        return reject(false)
                    }
                }
                //未全部支持element ui upload组件的该方法逻辑，只支持返回Boolean值
                const beforeUpload = this.beforeUpload;
                const allowed = !isFunction(beforeUpload) || beforeUpload(rawFile) !== false;
                if (allowed && this.multiple) {
                    //多文件上传时记录个数，保证此次选中的所有文件上传完成后再更新fileList
                    let uploadingFileCount = this.uploadingFileCount || 0;
                    if (!uploadingFileCount) {
                        this.fileQueueNotUpadted = [];
                    }
                    this.uploadingFileCount = ++uploadingFileCount;
                }
                return resolve(true);
            });
        },
        async downloadFromServer({id, path, name}) {
            if (this.private) {
                const blobUrl = await this.getBlobUrlOfAttachment(id, true);
                downloadFile(blobUrl, name);
            } else {
                downloadFile(this.formatToDownloadUrl(path, name), name);
            }
        },
        downloadFromLocalFile(rawFile) {
            let blobUrl = this.createBlobUrlOfFile(rawFile);
            return downloadFile(blobUrl, rawFile.name);
        },
        async transformToUrl(file) {
            if (file.raw) {
                let objectURL = this.createBlobUrlOfFile(file.raw);
                return {thumbnail: objectURL, source: objectURL, alt: file.name};
            } else if (file.id) {
                let url;
                if (this.private) {
                    url = await this.getBlobUrlOfAttachment(file.id, true);
                } else {
                    url = this.formatToDownloadUrl(file.path, file.name);
                }
                return {thumbnail: url, source: url, alt: file.name};
            }
            return null;
        },
        internalRemove(file, fileList) {
            if (file) {
                const copiedFileList = [...this.fileList];
                const foundItem = copiedFileList.find(item => item.uid === file.uid);
                if (foundItem && remove(copiedFileList, foundItem)) {
                    this.internalFileList = copiedFileList;
                }
            }
        },
        internalPreview(file) {
            this.selectedFile = file;
        },
        download() {
            const file = this.selectedFile;
            if (file.raw) {
                this.downloadFromLocalFile(file.raw);
            } else if (file.id) {
                this.downloadFromServer(file);
            }
        },
        isPreviewable(file) {
            return file && this.isImage(file.name);
        },
        previewImage() {
            const file = this.selectedFile;
            const imageFiles = this.$refs.uploader.uploadFiles.filter(file => this.isImage(file.name));

            const loading = this.$loading({lock: true});
            const imagePromises = imageFiles.map(file => this.transformToUrl(file));
            Promise.all(imagePromises)
                .then((images) => {
                    this.$refs.imageViewer.show(images, imageFiles.indexOf(file));
                }).finally(() => setTimeout(loading.close.bind(loading), 500));
        },
        handleClick(event) {
            const element = event.target;
            if (hasClass(element, 'el-icon-document')
                || hasClass(element, 'el-upload-list__item-name')
                || hasClass(element, 'el-upload-list__item-thumbnail')
                || hasClass(element, 'image-file-name')) {
                this.selectedFileElement = element;
                this.contextmenuVisible = true;
            }
        },
        hideContextmenu() {
            this.contextmenuVisible = false;
        },
        //文件超出限制个数回调
        exceed(files, fileList) {
            this.$message.error(`最多上传${this.limit}个文件!`)
        },
        // 限制图片尺寸
        limitFileWH(file) {
            const _this = this
            let imgWidth = ''
            let imgHight = ''
            const isSize = new Promise(function (resolve, reject) {
                const width = _this.picWidth
                const height = _this.picHeight
                const _URL = window.URL || window.webkitURL
                const img = new Image()
                img.onload = function () {
                    imgWidth = img.width
                    imgHight = img.height
                    const valid = img.width === width && img.height === height
                    valid ? resolve() : reject()
                }
                img.src = _URL.createObjectURL(file)
            }).then(() => {
                return true
            }, () => {
                _this.$message.error({
                    message: '上传图片的尺寸应为' + _this.picWidth + '*' + _this.picHeight + '，当前上传图片的尺寸为：' + imgWidth + '*' + imgHight,
                    btn: false
                })
                return false
            })
            return isSize
        },
    },
    mounted() {
        if (this.disabled) {
            this.$nextTick(() => {
                //移除不可编辑状态(展示时)下的上传触发器元素，避免对样式的影响
                const triggerElement = this.$el.querySelector(`.el-upload.el-upload--${this.listType}`);
                triggerElement.remove();
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.contextmenu {
    background: #fff;
    list-style-type: none;
    padding: 5px 0;
    font-weight: 400;
    color: #333;

    li {
        margin: 0;
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
            background: #eee;
        }
    }
}

.el-upload-list__item-thumbnail {
    cursor: pointer;
}

.image-file-name {
    opacity: 1;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    cursor: pointer;
    text-align: center;
    color: #ff0017;
    font-size: 12px;
    transition: opacity .3s;
}
</style>
