export function downloadFile(href, filename) {
    if (href && filename) {
        let a = document.createElement("a");
        a.download = filename; //指定下载的文件名
        a.href = href; //  URL对象
        a.target = "_blank";
        (document.body || document.documentElement).appendChild(a);

        a.click(); // 模拟点击
        a.remove();
        URL.revokeObjectURL(a.href); // 释放URL 对象
    }
}
